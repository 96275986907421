 .settings-icon-container {
  display:none;
  align-items: center;
  justify-content: flex-end;
  height:30px;
  width:100%;
  padding-right: 10px;
  margin:auto;
}

.settings-left {
  padding-top:30px;
}

.einstellungen-img {
    margin-left: 5px;
    margin-right: 5px;
    height: 22px;
    width: 22px;
}

.einstellungen-container {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 3px;
}

.einstellungen-container:hover {
    color: #fff !important;
    background-color: var(--selected-blue) !important;
    border-color: var(--selected-blue) ;
    text-decoration: none;
    cursor: pointer;
  }

.einstellungen-box {
        background-color: white;
        box-shadow: 0 1px 4px rgb(0 0 0 / 16%), 0 1px 4px rgb(0 0 0 / 23%);
        border-radius: 4px;
        height:auto;
        width:auto;  
        overflow:auto !important;   
}

.einstellungen-box-email {
  width:380px;
  margin-left:30px;
  margin-top:30px;
  margin-bottom:30px;
}

.email-container {
width:90%;
display:flex;
justify-content: center;
align-items:center;
flex-wrap: wrap;
}


 #settings-page-view-container td{
    text-align: center ; 
    min-width:30px;
    position: relative;
    border: 1px solid silver;
    padding:10px;
   
 }

 #settings-page-view-container th{
    text-align: center ; 
    min-width:80px;
    padding:10px;
    font-weight: 400;
    border: 1px solid silver;
    
 } 
 
 
 #settings-page-view-container table {
     word-wrap:normal;
     white-space: normal;
     border-spacing:2px;
     position: relative;
    
 }
 
 
 


 .color-picker {
     height:20px;
     border: 1px solid silver;
     width:20px;
     border-radius: 3px;
 }

 .color-picker-container {
     display:flex;
     justify-content: center;
     width:45px;
     height:30px;
     align-items: center;
     margin:auto;
     background-color: #e9ecef;
     border: 1px solid silver;
     border-radius: 3px;
     cursor:pointer;

 }


 #pop-up-settings{
    position: absolute;
    z-index: 10000;
    height:650px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 450px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 16%), 0 1px 4px rgb(0 0 0 / 23%);
    background-color: white;
}

#pop-up-settings .react-numeric-input{
  width:100% !important;
}




/* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist*/
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before
{
  content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before
{
  content: 'Times New Roman';
  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before
{
  content: 'Arial';
  font-family: 'Arial';
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/
.ql-font-arial {
  font-family: 'Arial';
}

.ql-font-times-new-roman {
  font-family: 'Times New Roman';
}








.quill-box {
  width:650px;
  height:450px;
} 


.token {
  display: inline-block;
  border-radius: 0.75em;
  background-color: #07c;
  color: #fff;
  padding: 0 8px;
  line-height: 1.5;
  white-space: nowrap;
  font-size: 13px;
  user-select: none;
  margin-left: 1px;
  margin-right: 1px;
  border: none;
}


.token * {
  pointer-events: none;
}

.token-list {
  list-style-type: none;
  padding-left: 0;
  height:492px;
  width:150px;
  border: 1px solid silver;
  border-right: none;
  padding:0px;
}

.token-list .token {
  cursor: pointer;
}





.email-header {
  border: 1px solid silver;
  border-bottom: none;
  padding:5px 0px 5px 5px;
}

.email-footer {
  display:flex;
  justify-content:flex-end;
  align-items:center;
  border: 1px solid silver;
border-top:none;
padding:7px;
}

.email-header-input {
   width:96%; 
  margin-left: 5px;
}



@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{

  .settings-icon-container {
    display:flex;
  }

.settings-left {
  padding-top:0px;
}

.quill-box {
  width:75vw;
} 

  

}



@media only screen and (max-width : 767px) and (orientation : portrait)  {

  .settings-icon-container {
    display:flex;
  }

  #pop-up-settings{
    height:60vh;
    width: 85vw;
}

.settings-left {
  padding-top:0px;
}

.email-container {
display:none;
  }
  

  }

  
@media only screen and (max-width : 1023px) and (orientation : landscape)  {

  .settings-icon-container {
    display:flex;
  }

  #pop-up-settings{
    height:80vh;
    width: 70vw;
}

.settings-left {
  padding-top:0px;
}

.email-container {
  display:none;
    }

  }


 